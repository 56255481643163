"use client";

import { useState } from "react";
import Link from "next/link";
import { IoCloseOutline } from "react-icons/io5";
import { useRouter } from "next/navigation";
import { isValidUrl, validateSiteUrl } from "@/utils/url_validate";

export default function SearchBar({
  placeholder,
  sampleUrl,
  onOverview,
}: {
  placeholder: string;
  sampleUrl?: string;
  onOverview?: (query: string) => void;
}) {
  const router = useRouter();
  const [searchTerm, setSearchTerm] = useState("");
  const [error, setError] = useState("");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleClear = () => {
    setSearchTerm("");
    setError("");
  };

  function validateUrl(): string | null {
    let processedUrl = searchTerm.trim();

    // validate url format
    if (!isValidUrl(processedUrl)) {
      setError("Invalid URL format");
      return null;
    }

    // ensure url has protocol
    if (
      !processedUrl.startsWith("http://") &&
      !processedUrl.startsWith("https://")
    ) {
      processedUrl = "https://" + processedUrl;
    }

    const siteError = validateSiteUrl(processedUrl);
    if (siteError) {
      setError(siteError);
      return null;
    }

    // validate url validity
    try {
      new URL(processedUrl);
      return processedUrl;
    } catch (error) {
      setError(`Invalid URL format: ${error}`);
      return null;
    }
  }

  const handlePreview = () => {
    const processedUrl = validateUrl();
    if (processedUrl) {
      if (onOverview) {
        onOverview(processedUrl);
      } else {
        router.push(`/overview?query=${processedUrl}`);
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handlePreview();
    }
  };

  return (
    <div className="flex flex-col items-start w-full gap-2">
      <div className="relative flex items-center w-full">
        <input
          type="text"
          value={searchTerm}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
          className="w-full px-4 py-2 text-sm text-gray-800 bg-white border border-gray-300 rounded-lg focus:ring-2 focus:ring-gray-400 focus:border-transparent placeholder:text-gray-800 placeholder:opacity-60"
        />
        <div className="absolute right-0 flex items-center pr-1.5">
          {searchTerm && (
            <button
              onClick={handleClear}
              className="p-1 text-gray-600 hover:text-gray-800"
            >
              <IoCloseOutline className="w-5 h-5" />
            </button>
          )}
          <button
            onClick={handlePreview}
            disabled={!searchTerm}
            className={`ml-2 px-3 py-1 text-sm font-medium rounded-md ${
              searchTerm
                ? "bg-black text-white hover:bg-gray-600"
                : "bg-gray-200 text-gray-400 cursor-not-allowed"
            }`}
          >
            GO
          </button>
        </div>
      </div>
      {error && <div className="text-red-500 text-sm px-4">{error}</div>}
      {sampleUrl && (
        <div className="relative flex justify-start w-full px-4">
          <span className="text-gray-600 text-sm pr-1 whitespace-nowrap">
            Try sample
          </span>
          <Link
            href={`/overview?query=${sampleUrl}`}
            title={sampleUrl}
            onClick={(e) => {
              if (onOverview) {
                e.preventDefault();
                onOverview(sampleUrl);
              }
            }}
            className="text-blue-600 hover:underline text-sm break-all max-w-[calc(100vw-8rem)]"
          >
            {sampleUrl}
          </Link>
        </div>
      )}
    </div>
  );
}
