const piracySites = new Set([
  "dood.to",
  "dood.watch",
  "dood.so",
  "dood.pm",
  "dood.wf",
  "dood.re",
  "viewsb.com",
  "filemoon.sx",
  "hentai.animestigma.com",
  "thisav.com",
  "gounlimited.to",
  "highstream.tv",
  "uqload.com",
  "vedbam.xyz",
  "vadbam.net",
  "vidlo.us",
  "wolfstream.tv",
  "xvideosharing.com",
  "sxyprn.com",
  "jable.tv",
  "91porn.com",
  "einthusan.tv",
  "einthusan.com",
  "einthusan.ca",
  "yourupload.com",
  "pornhub.com",
]);

const unsupportedSites = new Set([
  "douyin.com",
  "avjb.com",
  "peliculaspornonline.com",
  "bing.com",
  "taimei-l724.cc",
]);

function validateBilibiliUrl(url: string): string | null {
  if (url.includes("bilibili.com/bangumi")) {
    return "Bangumi format is not supported at this time.";
  }
  return null;
}

function validateFacebookUrl(url: string): string | null {
  if (url.includes("facebook.com/share")) {
    return "Facebook share format is not supported, you can open the url to get the direct download link.";
  }
  return null;
}

const siteValidationMap = new Map([
  ["facebook.com", validateFacebookUrl],
  ["bilibili.com", validateBilibiliUrl],
]);

export function isValidUrl(url: string): boolean {
  // validate url format
  const urlPattern = /^(https?:\/\/)?([\w-]+(\.[\w-]+)+)(\/[\w-./@?%&=]*)?$/i;
  if (!urlPattern.test(url)) {
    return false;
  }
  return true;
}

export function validateSiteUrl(url: string): string | null {
  const domain = url.split("/")[2];
  const parts = domain.split(".");
  const mainDomain = parts.length > 2 ? parts.slice(-2).join(".") : domain;
  console.log(mainDomain);

  if (piracySites.has(mainDomain)) {
    return `${mainDomain} is not supported due to copyright concerns.`;
  }
  if (unsupportedSites.has(mainDomain)) {
    return `${mainDomain} is not supported at this time.`;
  }

//   if (["youtube.com", "youtu.be"].includes(mainDomain)) {
//     return "We are experiencing some issues with YouTube downloads. We are working to fix this as soon as possible.";
//   }

  const validator = siteValidationMap.get(mainDomain);
  if (validator) {
    return validator(url);
  }
  return null;
}
